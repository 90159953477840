import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import "../styles/blogs.css"

export default ({ data }) => {
  return (
    <Layout>
      <div className="blogs-page">
        <h2>Here are some of the latest blogs that I've wrote:</h2>
        <div className="all-blogs">
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <div key={node.id} className="blog-post">
              <Link
                to={node.fields.slug}
                className="blog-link"
                rel="noreferrer"
              >
                <h3 className="blog-title">{node.frontmatter.title}</h3>
                <p className="blog-intro">{node.frontmatter.intro}</p>
                <div
                  className="blog-image"
                  style={{
                    backgroundImage: `url(${node.frontmatter.featuredImage.childImageSharp.fluid.src})`,
                  }}
                ></div>
              </Link>
            </div>
          ))}
        </div>
        <h4>
          You can check out more on{" "}
          <a
            href="https://medium.com/@ashcodes"
            target="_blank"
            style={{ color: "white" }}
          >
            my Medium account
          </a>
          .
        </h4>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            intro
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          excerpt
          fields {
            slug
          }
        }
      }
    }
  }
`
